import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import Captcha from "../components/captcha"
import "animate.css/animate.min.css"


export default class checklistpage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            name: "",
            email: "",
            phone: "",
            website: "",
            message: "",
            response: "",
            displayResult: false,
            displayClass: '',
            _currentURL: '',
            code: this.generateCaptchaCode(),
            captcha: '',
            key: Date.now(),
            submit_text: 'Submit',
            is_processing: false,
            host: ''
        };
        this.changeCaptcha = this.changeCaptcha.bind(this);
    }

    generateCaptchaCode() {
        let charsArray =
            "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
        let lengthOtp = 6;
        let captcha = [];
        for (let i = 0; i < lengthOtp; i++) {
            //below code will not allow Repetition of Characters
            let index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
            if (captcha.indexOf(charsArray[index]) === -1)
                captcha.push(charsArray[index]);
            else i--;
        }

        return captcha.join("");
    }

    componentDidMount() {
        this.setState({
            url: document.location.href,
            host: document.location.host,
            // code:this.generateCaptchaCode(),
        });
    }
    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(email)
    }
    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'website') {
            let urlregex = new RegExp("^(?:https?:\\/\\/)?(?!www | www\\.)[A-Za-z0-9_-]+\\.+[A-Za-z0-9.\\/%&=\\?_:;-]+$");

            // if(value.trim().length>0) {
            if (!urlregex.test(value.trim())) {
                target.setCustomValidity("Please enter a valid domain name. example.com");
            } else {
                target.setCustomValidity("");
            }
            // }else{
            //     target.setCustomValidity("");
            // }
        }

        if (name === "email") {
            if (!this.validateEmail(value.trim().toLowerCase())) {
                target.setCustomValidity("Please enter a valid email. email@domain.com")
            } else {
                target.setCustomValidity("")
            }
        }

        this.setState({
            [name]: value,
        })
    };

    changeCaptcha() {
        this.setState({
            code: this.generateCaptchaCode(),
            key: Date.now()
        });
    }

    handleSubmit = event => {
        event.preventDefault();
        //check captcha
        if (this.state.captcha !== this.state.code) {
            this.setState({
                response: 'Captcha not matched',
                displayResult: true,
                displayClass: 'error-msg'
            });
            setTimeout(() => {
                this.setState({ displayResult: false });
            }, 3000);
            return
        }

        this.setState({
            submit_text: 'Submitting...',
            is_processing: true,
        })

        let HUBSPOT_CONTACT_FORM
        if (this.state.host === "cb-redesign.netlify.com" || this.state.host === "cb-redesign.netlify.app") {
            //STAGING FORM
            HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/92a6501c-84a7-494d-aa44-1398b37b997c"
        } else if (this.state.host === "www.cueforgood.com" || this.state.host === "cueforgood.com") {
            //LIVE FORM
            HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/d77e403a-ae3a-45ae-bd88-0bdae863fff5"
        } else {
            //STAGING FORM
            HUBSPOT_CONTACT_FORM = "https://api.hsforms.com/submissions/v3/integration/submit/6881310/92a6501c-84a7-494d-aa44-1398b37b997c"
        }
        let data = {
            "submittedAt": new Date().getTime(),
            "fields": [
                {
                    "name": "email",
                    "value": this.state.email
                },
                {
                    "name": "firstname",
                    "value": this.state.name
                },
                {
                    "name": "phone",
                    "value": this.state.phone
                },
                {
                    "name": "website",
                    "value": this.state.website
                },
                {
                    "name": "message",
                    "value": this.state.message
                }
            ],

            "legalConsentOptions": { // Include this object when GDPR options are enabled
                "consent": {
                    "consentToProcess": true,
                    "text": "I agree to allow Example Company to store and process my personal data.",
                    "communications": [
                        {
                            "value": true,
                            "subscriptionTypeId": 999,
                            "text": "I agree to receive marketing communications from Example Company."
                        }
                    ]
                }
            }
        };

        const final_data = JSON.stringify(data);

        // try {
        let _message = '';
        let _this = this;
        fetch(HUBSPOT_CONTACT_FORM, {
            method: "post",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: final_data
        }
        ).then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                response.json().then(function (response) {
                    response.errors.forEach((data, index) => {
                        if (_message === '') {
                            _message = data.message;
                        } else {
                            _message += '</br>' + data.message;
                        }
                    });
                    _this.setState({
                        response: _message,
                        displayResult: true,
                        displayClass: 'error-msg',
                        submit_text: 'Submitted',
                        is_processing: false,
                    });
                    _this.changeCaptcha();
                    setTimeout(() => {
                        _this.setState({ displayResult: false, submit_text: 'Submit' });
                    }, 5000);
                });
                throw Error('Missing params');
            }
        }).then((response) => {
            _this.setState({
                response: response.inlineMessage,
                name: "",
                email: "",
                phone: "",
                message: "",
                website: "",
                about: "",
                captcha: "",
                displayResult: true,
                displayClass: 'success-msg',
                submit_text: 'Submitted',
                is_processing: false,
            });
            _this.changeCaptcha();
            setTimeout(() => {
                _this.setState({ displayResult: false, submit_text: 'Submit' });
            }, 5000);
        }).catch((error) => {
            console.error(error);
            _this.setState({ submit_text: 'Submit' });
        })
    };

    render() {
        return <Layout>
            <Helmet>
                <title>Download 40-Point Checklist for HTTPs Migration | Free How-to Guide for HTTP to HTTPS Migration
                    of Stores</title>
                <link rel="canonical"
                    href={this.state.url} />
                <meta name="description"
                    content="The How-to guide you need before executing your store's HTTP to HTTPs migration. The guide lists 40 points that ensure SEO success and performance, post migration." />
                <meta name="viewport"
                    content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no" />
                <link rel="shortcut icon" href="https://www.cueforgood.com/favicon.ico" />
                <meta property="og:site_name" content="CueForGood" />
                <meta property="fb:app_id" content="289086684439915" />
                <meta property="og:url" content={this.state.url} />
                <meta property="og:title" content="Download 40-Point Checklist for HTTPs Migration | Free How-to Guide for HTTP to HTTPS Migration
                    of Stores"/>
                <meta property="og:description"
                    content="The How-to guide you need before executing your store's HTTP to HTTPs migration. The guide lists 40 points that ensure SEO success and performance, post migration. " />
                <meta property="og:image" content="https://www.cueforgood.com/images/cb.jpg" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:description"
                    content="The How-to guide you need before executing your store's HTTP to HTTPs migration. The guide lists 40 points that ensure SEO success and performance, post migration." />
                <meta name="twitter:title" content="Download 40-Point Checklist for HTTPs Migration | Free How-to Guide for HTTP to HTTPS Migration
                    of Stores"/>
                <meta name="twitter:site" content="@CueForGood" />
                <meta name="twitter:image" content="https://www.cueforgood.com/images/cb.jpg" />
            </Helmet>
            <article className="holly">
                <section className="main seo">
                    <div className=" wrap">
                        <div className="get_free">
                            <div className="head">
                                <p>Get a free copy of</p>
                                <h1>the 40-Point Checklist for A Successful Http to Https Migration Infographic
                                    included!</h1>
                            </div>
                            <div id="mc_embed_signup" className="mc_embed_signup1">
                                <form
                                    action="//cueblocks.us5.list-manage.com/subscribe/post?u=f1428e4bc08f78bd02dc4abda&amp;id=73e460210b"
                                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                                    className="validate" target="_blank" noValidate>
                                    <div className="mc-field-group contact-form__block">
                                        <div className="form__row">
                                            <input type="email" placeholder="Send me a copy" name="EMAIL"
                                                className="email" />
                                            <input type="hidden" name="LOC2" value="SEO Audit" />
                                            <div className="border-line"></div>
                                        </div>
                                        <div className="btn_go">
                                            <input type="submit" name="subscribe"
                                                id="mc-embedded-subscribe" value="Send" className="button main_cta" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <p className="point-text">We will also use your email address to send you informative, relevant, and valuable information on eCommerce design, development, and marketing.</p>

                        </div>

                        <div className="wrapb1_right  contact-form__block">
                            <div className="cont_frm">
                                <h2>Get SEO Audit</h2>
                                <p>Want to increase the Search Engine Ranking of your store? Get our SEO experts to
                                    evaluate your store. </p>
                                <span className="indicate">(<em>*</em> indicates mandatory fields)</span>
                                <div className="cont-frm1">
                                    <form method="post" name="contact-form" id="contact-form"
                                        onSubmit={this.handleSubmit}>
                                        <div className="form__row">
                                            <label>Name<em>*</em></label>
                                            <input type="text" required name="name" id="name"
                                                placeholder="Enter your name" className="input1"
                                                value={this.state.name} onChange={this.handleInputChange} />
                                            <div className="border-line"></div>
                                        </div>
                                        <br />
                                        <div className="form__row">
                                            <label>Email<em>*</em></label>

                                            <input type="text" required name="email" id="email" placeholder="Enter your email"
                                                title="hello@domain.com"
                                                className="input1" value={this.state.email}
                                                onChange={this.handleInputChange} />
                                            <div className="border-line"></div>
                                        </div>
                                        <br />
                                        <div className="form__row">
                                            <label>Website<em>*</em></label>
                                            <input type="text" required name="website" id="website"
                                                placeholder="Enter website here"
                                                title="example.com"
                                                className="input1"
                                                value={this.state.website}
                                                onChange={this.handleInputChange} />
                                            <div className="border-line"></div>
                                        </div>
                                        <br />
                                        <div className="form__row">
                                            <label>Phone<em>*</em></label>
                                            <input type="text" required name="phone" id="phone"
                                                placeholder="+1-123-456-789"
                                                className="input1" value={this.state.phone}
                                                onChange={this.handleInputChange} />
                                            <div className="border-line"></div>
                                        </div>
                                        <br />
                                        <label>Message<em>*</em></label>
                                        <textarea name="message" required rows="4" id="message" cols="17"
                                            placeholder="Write message here"
                                            className="required" value={this.state.message}
                                            onChange={this.handleInputChange}></textarea>
                                        <br />

                                        <div className="form__row captcha-row" key={this.state.key}>
                                            <Captcha {...this.state} /><em>*</em><span className="chnage-captcha"
                                                onClick={this.changeCaptcha}> Change</span>

                                            <input type="text" required name="captcha" id="captcha"
                                                placeholder="Captcha"
                                                className="input1" autoComplete={'off'} value={this.state.captcha}
                                                onChange={this.handleInputChange} />

                                        </div>
                                        <br /> <br />
                                        <input type="submit" name="submit" id="submit-contact"
                                            value={this.state.submit_text}
                                            className="submit_con main_cta " disabled={this.state.is_processing} />


                                        {this.state.displayResult ?
                                            <div className={this.state.displayClass}
                                                style={{ textAlign: 'center', marginTop: '10px' }}
                                                dangerouslySetInnerHTML={{ __html: this.state.response }}></div> : ''
                                        }
                                    </form>
                                </div>
                            </div>

                        </div>

                        <div className="wrapb1">
                            <div className="wrapb1_left">
                                <h2>A Must Have Guide for Your Store’s Migration Program</h2>
                                An Easy-to- implement 40-step guide to ensure that your migration from the Http to
                                Https version of the website is smooth, beneficial, and secure! There’s a printable
                                infographic that you can take out and post on your wall as a go-to
                                reminder during your migration!
                                <h2>Crucial Take Aways:</h2>
                                <ul>
                                    <li>Tips to ensure a smooth switch to the Https version of the website</li>
                                    <li>Step-by- step breakdown of complex essentials for store owners and admins to
                                        remember during the Http to Https migration
                                    </li>
                                    <li>Relevant for both eCommerce and non-eCommerce stores</li>
                                    <li>Implementable Points that combat the risk of lowered rankings, reduced
                                        visibility in search engines, compromised data and other unforeseen
                                        repercussions.
                                    </li>

                                </ul>
                                The information contains all the essential points one needs to keep in mind when
                                executing migration of a store and avoid penalization of your store's search engine
                                performance. It's concise, quick to follow, and extremely useful! Go ahead, migrate your
                                store and do it with knowledge.
                                <br />
                                <br />
                            </div>
                            <div className="clr"></div>
                        </div>
                    </div>
                </section>
            </article>

        </Layout>
    }
}
